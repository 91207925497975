var __tnt = window.__tnt || {};
    googletag = window.googletag || {};
    gptAdSlots = window.gptAdSlots || [];

__tnt.ads = window.__tnt.ads || {};
__tnt.ads.slots = __tnt.ads.slots || {};
googletag.cmd = window.googletag.cmd || [];

__tnt.ads.refreshPos = function(pos, settings) {
    if (location.href.indexOf('tncms/admin') != -1) return;
    try {
        var onlyDfp = false;
        if (pos) pos = pos.split(',');
        
        if (typeof gptAdSlots != 'undefined') {
            if (pos) {
                onlyDfp = true;
                var adSlots = null;
                adSlots = [];
                for (var i=0; i<pos.length; i++) {
                    adSlots.push(gptAdSlots[pos[i]]);
                }
            }
            if (settings && settings.destroy) {
                googletag.cmd.push(function(){
                    googletag.pubads().clear(adSlots);
                });
            } else {
                googletag.cmd.push(function(){
                    googletag.pubads().clear(adSlots);
                    googletag.pubads().refresh(adSlots);
                });
            }
        }
        
        if (settings && !settings.destroy) {
            if (!onlyDfp && typeof TNCMS.AdManager != 'undefined') {
                for (var i=0; i<pos.length; i++) {
                    TNCMS.AdManager.refresh({region: pos[i]});
                }
            }
        }
    } catch(e) {
        console.log(e);
    }
};

(function(window, document, tntSlots, googletag, gptAdSlots) {

    var utils = (function() {

        var me = {};

        me.characterCount = function(count) {
            var counts = utils.getMeta('tncms:ad_instory');
            if (counts) {
                counts = counts.split(',');
            }

            if (count == 1) {
                return counts[0] || 580;
            } else {
                if (window.innerWidth < 768) {
                    return counts[1] || 776;
                } else if (window.innerWidth < 992) {
                    return counts[2] || 1164;
                } else {
                    return counts[3] || 1552;
                }
            }
        };

        me.debugMode = function() {
            if (/[?&]ads_log/.test(window.location.search)) {
                return true;
            }
            return false;
        };

        me.filterSizes = function(sizes, content, adUnit) {
            var contentWidth = content.offsetWidth;
            var customSizes = false;

            // Use custom sizes if they're defined
            if (adSizesXs || adSizesSm || adSizesMd || adSizesLg) {
                customSizes = true;
                if (window.innerWidth < 768 && adSizesXs) {
                    sizes = adSizesXs;
                } else if (window.innerWidth < 992 && adSizesSm) {
                    sizes = adSizesSm;
                } else if (window.innerWidth < 1200 && adSizesMd) {
                    sizes = adSizesMd;
                } else if (adSizesLg) {
                    sizes = adSizesLg;
                } else {
                    sizes = [];
                }
            }

            /* Remove 2x1 size for content exchange */
            if (adUnit === '/132916964/cen') {
                sizes = sizes.filter(function(size) {
                    return (size !== 'fluid' && size[0] === 2 && size[1] === 1) ? false : true;
                });
            }

            /* Remove 320x50 and 300x50 from desktop, laptop, and tablet viewports */
            if (!customSizes && window.innerWidth >= 768) {
                sizes = sizes.filter(function(size) {
                    return (size !== 'fluid' && (size[0] === 320 && size[1] === 50) || (size[0] === 300 && size[1] === 50)) ? false : true;
                });
            }

            /* Filter out sizes less than content width */
            sizes = sizes.filter(function(size) {
                return (size === 'fluid' || size[0] <= contentWidth) ? true : false;
            });

            /* Fallback size array when too many sizes are filtered out. */
            if (!customSizes && sizes.length < 3) {
                if (window.innerWidth < 768) {
                    sizes = [[300,250],[320,50],[300,50],[2,1],'fluid'];
                } else {
                    sizes = [[300,250],[2,1],'fluid'];
                }
            }

            return sizes;
        };

        me.getMeta = function(property) {
            var metaTag = document.head.querySelector('meta[property="' + property + '"]');
            return (metaTag) ? metaTag.content : false;
        };

        me.hasPrebid = function(id) {
            var prebid = false;
            if (typeof window.adUnits !== 'undefined') {
                for (var key in window.adUnits) {
                    if (window.adUnits.hasOwnProperty(key)) {
                        if (window.adUnits[key].code == id) {
                            prebid = true;
                            return false;
                        }
                    }
                }
            }
            return prebid;
        };

        me.inViewport = function(id) {
            if (document.getElementById(id)) {
                if (__tnt.ads.slots[id].sticky) {
                    return true;
                /* special logic for breakout regions */
                } else if (id.indexOf('breakout') != -1) {
                    var bounding = document.getElementById(id).getBoundingClientRect();
                    return (
                        Math.round(bounding.top) >= 0 &&
                        Math.round(bounding.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
                        Math.round(bounding.width) <= window.innerWidth
                    );
                } else {
                    var bounding = document.getElementById(id).getBoundingClientRect();
                    return (
                        Math.round(bounding.top) >= 0 &&
                        Math.round(bounding.left) >= 0 &&
                        Math.round(bounding.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
                        Math.round(bounding.right) <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                }
            }
        };

        me.limitRefreshSizes = function(id, sizes) {
            var iframe = document.getElementById(id).querySelector('iframe');

            if (iframe) {
                var sizeArray = [];
                
                for (var i = 0; i < sizes.length; i++) {
                    if (sizes[i][0] === 2 && sizes[i][1] === 1) continue;
                    if (sizes[i][0] === iframe.offsetWidth && sizes[i][1] === iframe.offsetHeight) {
                        sizeArray.push(sizes[i]);
                        return sizeArray;
                    }
                }
            }
            return false;
        };

        me.log = function(error) {
            if (window.console) {
                window.console.log(error);
            }
        };

        return me;
    })();

    var adCount = 1;
    var mobileRefresh = document.body.dataset.adsMobileRefresh === 'true' && window.innerWidth < 992;
    var observerMargin = (utils.getMeta('tncms:ads_lazyload_margin')) ? utils.getMeta('tncms:ads_lazyload_margin') : (mobileRefresh ? '150px' : '300px');
    var initialized = false;

    var TnAds = {

        _init: function() {
            if (initialized) {
                if (debug) utils.log('TnAds already initialized');
                return false;
            }
            try {
                features = utils.getMeta('tncms:ads').split(',');
                refresh_interval = utils.getMeta('tncms:ad_refresh_interval') ? parseInt(utils.getMeta('tncms:ad_refresh_interval')) : 20000;
                adSizesLg = utils.getMeta('tncms:ad_instory_sizes_lg') ? JSON.parse(utils.getMeta('tncms:ad_instory_sizes_lg')) : null;
                adSizesMd = utils.getMeta('tncms:ad_instory_sizes_md') ? JSON.parse(utils.getMeta('tncms:ad_instory_sizes_md')) : null;
                adSizesSm = utils.getMeta('tncms:ad_instory_sizes_sm') ? JSON.parse(utils.getMeta('tncms:ad_instory_sizes_sm')) : null;
                adSizesXs = utils.getMeta('tncms:ad_instory_sizes_xs') ? JSON.parse(utils.getMeta('tncms:ad_instory_sizes_xs')) : null;
                encrypted = document.getElementById('article-body') && document.getElementById('article-body').dataset.contentEncrypted === 'true';

                var dmp = (features.indexOf('dmp') != -1) ? true : false,
                    lazyload = (features.indexOf('lazyload') != -1) ? true : false,
                    refresh = (features.indexOf('refresh') != -1) ? true : false,
                    refresh_timer = (features.indexOf('refresh_timer') != -1) ? true : false,
                    smart = (features.indexOf('smart') != -1) ? true : false;

                if (lazyload || refresh || refresh_timer || smart) {
                    if (smart) {
                        if (encrypted) {
                            window.addEventListener('tnt-subscription-content-decrypted', function() {
                                TnAds.initAdInjection(null, true);
                            });
                        } else {
                            TnAds.initAdInjection();
                        }
                    }
                    if (lazyload || refresh || refresh_timer) TnAds.getEligibleSlots();
                    if (dmp) TnAds.progTargeting();
                    TnAds.observeAds();
                }
            } catch(e) {
                if (debug) utils.log(e);
                return false;
            }
            initialized = true;
        },

        _initHandler: function(e) {
            if (e.type === 'tnt-dmp-audience-extracted-segment' || e.type === 'tnt-dmp-audience-extracted-lotame') {
                userAudience = e.detail.userAudience;
                if (!subscription || userStatusDefined) TnAds._init();
            } else if (e.type === 'tnt-user-status') {
                userStatusDefined = true;
                if (!audienceTargeting || typeof userAudience !== 'undefined') TnAds._init();
            }
        },

        createAdTag: function(id) {
            var ad = document.createElement('div'),
                container = document.createElement('div'),
                fragment = document.createDocumentFragment();

            ad.id = id;
            ad.className = 'tnt-ads dfp-smart-ad';
            container.className = 'tnt-ads-container text-center';

            fragment.appendChild(container);
            fragment.querySelector('.tnt-ads-container').appendChild(ad);
            return fragment;
        },

        defineGAMSlot: function(adUnit, id, sizes, position) {
            googletag.cmd.push(function() {
                var mapping = googletag.sizeMapping().addSize([0,0], sizes).build();
                gptAdSlots[id] = googletag.defineSlot(adUnit, [2,1], id).defineSizeMapping(mapping).setCollapseEmptyDiv(true).addService(googletag.pubads());
                gptAdSlots[id].setTargeting('pos', position);
            });
        },

        destroySlot: function(id) {
            googletag.cmd.push(function() {
                googletag.destroySlots([gptAdSlots[id]]);
            });
        },

        fetchAds: function(ads) {

            // Sort ads by vendor
            var bloxAds = [],
                gamAds = [];

            for (var i = 0; i < ads.length; i++) {
                if (ads[i].vendor === 'blox') {
                    bloxAds.push(ads[i]);
                } else if (ads[i].vendor === 'gam') {
                    gamAds.push(ads[i]);
                }
            }

            // BLOX Ad Manager
            if (bloxAds.length > 0) {
                for (var i = 0; i < bloxAds.length; i++) {
                    TNCMS.AdManager.refresh({'region': bloxAds[i].region});
                }
            }

            // Google Ad Manager
            if (gamAds.length > 0) {

                // Sort GAM ads for header bidding
                var magniteIds = [],
                    ozoneIds = [],
                    a9Ids = [],
                    gptIds = [],
                    prebidIds = [];

                for (var i = 0; i < gamAds.length; i++) {
                    if (utils.hasPrebid(gamAds[i].id) && (gamAds[i].initLoad || gamAds[i].biddingRefresh)) {
                        prebidIds.push(gamAds[i].id);
                    } else if (typeof pbjs !== 'undefined' && typeof pbjs.rp !== 'undefined' && (gamAds[i].initLoad || gamAds[i].biddingRefresh)) {
                        magniteIds.push(gamAds[i].id);
                    } else if (typeof ozonePubads !== 'undefined' && (gamAds[i].initLoad || gamAds[i].biddingRefresh)) {
                        ozoneIds.push(gamAds[i].id);
                    } else if (typeof apstag !== 'undefined' && (gamAds[i].initLoad || gamAds[i].biddingRefresh)) {
                        a9Ids.push(gamAds[i].id);
                    } else {
                        gptIds.push(gamAds[i].id);
                    }

                    // Disable initLoad variable
                    if (gamAds[i].initLoad) gamAds[i].initLoad = false;

                    // Reset viewability check after refresh
                    if (gamAds[i].viewableImpression && !gamAds[i].sticky) gamAds[i].viewableImpression = false;
                }

                // Magnite
                if (magniteIds.length > 0) {
                    googletag.cmd.push(function() {
                        magnite_refreshBid(magniteIds);
                    });
                }

                // Ozone
                if (ozoneIds.length > 0) {
                    googletag.cmd.push(function() {
                        var a9Enabled = typeof apstag !== 'undefined';
                        var ozoneSlots = [];
                        for (var i = 0; i < ozoneIds.length; i++) {
                            ozoneSlots.push(gptAdSlots[ozoneIds[i]]);
                        }
                        
                        if (a9Enabled) {
                            ozoneFetchBids(ozoneIds, function(id) {
                                apstag.fetchBids({
                                    slots: ozoneSlots,
                                    timeout: 2000
                                }, function(bids) {
                                    apstag.setDisplayBids();
                                    googletag.pubads().refresh(ozoneSlots);
                                });
                            });
                        } else {
                            ozoneFetchBids(ozoneIds, function(id) {
                                googletag.pubads().refresh(ozoneSlots);
                            });
                        }
                    });
                }

                // A9
                if (a9Ids.length > 0) {
                    googletag.cmd.push(function() {
                        var a9Slots = [];
                        for (var i = 0; i < a9Ids.length; i++) {
                            a9Slots.push(gptAdSlots[a9Ids[i]]);
                        }
                        apstag.fetchBids({ slots: a9Slots,
                            timeout: 2000 },
                        function(bids) {
                            apstag.setDisplayBids();
                            googletag.pubads().refresh(a9Slots);
                        }); 
                    });
                }

                // GPT - No header bidding
                if (gptIds.length > 0) {
                    googletag.cmd.push(function() {
                        var slots = [];
                        for (var i = 0; i < gptIds.length; i++) {
                            slots.push(gptAdSlots[gptIds[i]]);
                        }
                        googletag.pubads().refresh(slots);
                    });
                }

                // Prebid
                if (prebidIds.length > 0) {
                    pbjs_refreshBid(prebidIds);
                }
            }
        },

        getEligibleSlots: function() {
            var ads = document.querySelectorAll('.dfp-ad, .blox-ad, [data-tnt-ads-tmp]');

            for (var i = 0; i < ads.length; i++) {

                // Ad exclusions
                if (ads[i].id == 'fixed-big-ad-gallery' || ads[i].id == 'fixed-big-ad-paging-gallery') {
                    continue;
                } else if ((ads[i].style.display == 'none' && ads[i].classList.contains('dfp-ad')) || (ads[i].style.display == 'none' && ads[i].classList.contains('blox-ad'))) {
                    ads[i].remove();
                } else {
                    var vendor = (ads[i].classList.contains('dfp-ad') ? 'gam' : 'blox'),
                        slot = TnAds.verifySlot(ads[i], vendor);

                    if (slot) {
                        if (debug) utils.log(slot);
                        tntSlots[slot.id] = slot;
                    }
                }
            }
        },

        initAdInjection: function(assetElement, observeAds) {
            if (typeof __tnt.ads.dfp.dmp !== 'undefined' && typeof __tnt.ads.dfp.dmp.hideAudience !== 'undefined') {
                if (typeof userAudience === 'object' && userAudience.length > 0) {
                    for (var i = 0; i < userAudience.length; i++) {
                       userAudience[i] = userAudience[i].replace('keyword:', '');
                    }
                    if (__tnt.compareArray(userAudience, __tnt.ads.dfp.dmp.hideAudience)) return false;
                }
            }

            var assetElement = assetElement || document.getElementById('asset-content') || document.getElementById('infinity-story-asset');
            var assetContent = assetElement.querySelector('.asset-content');
            var nodes = assetContent.childNodes;

            adUnit = assetElement.dataset.smartAdunit || document.getElementById('infinity-story-asset').dataset.smartAdunit;

            if (nodes.length > 0) {
                filteredSizes = utils.filterSizes(adSizes, assetContent, adUnit);
                TnAds.insertAds(nodes, observeAds);
            }
        },

        insertAd: function(insertPos, node, observeAds) {
            if (insertPos !== 'after' && insertPos !== 'before') {
                throw new Error('insertPos argument expected one of "after" or "before"');
            }
            
            var slotId = 'tnt-smart-ad-' + adCount,
                slotObj = {
                    alwaysLoad: false,
                    id: slotId,
                    initLoad: false,
                    count: adCount,
                    lazyload: true,
                    refresh: true,
                    sticky: false,
                    timestamp: null,
                    type: 'injected',
                    vendor: 'gam',
                    viewableImpression: false
                };
                
            if (adCount === 1) slotObj.delayRefresh = true;

            if (insertPos == 'before') {
                node.parentNode.insertBefore(TnAds.createAdTag(slotId, adCount), node);
            } else if (insertPos == 'after') {
                node.parentNode.insertBefore(TnAds.createAdTag(slotId, adCount), node.nextSibling);
            }

            TnAds.defineGAMSlot(adUnit, slotId, filteredSizes, ['fixed_automatic_ad', 'fixed_automatic_ad' + adCount]);
            tntSlots[slotId] = slotObj;

            if (observeAds) {
                TnAds.lazyloadObserver.observe(document.getElementById(slotObj.id));
            }

            adCount++;
        },

        insertAds: function(nodes, observeAds) {
            var charCount = 0,
                articleAdCount = 1;

            for (var i = 0; i < nodes.length; i++) {
                if (nodes[i].tagName == 'P' || (nodes[i].tagName == 'DIV' && nodes[i].classList.contains('subscriber-preview')) || (nodes[i].tagName == 'DIV' && nodes[i].classList.contains('subscriber-only'))) {
                    if (nodes[i].tagName == 'DIV' && (!nodes[i].firstElementChild || nodes[i].firstElementChild.tagName != 'P')) continue;
                    var node = (nodes[i].tagName == 'DIV') ? nodes[i].firstChild : nodes[i],
                        paraLength = node.innerHTML.replace(/<[^>]*>/g, " ").replace(/\s+/g, ' ').trim().length;

                    charCount = charCount + paraLength;

                    if (debug) {
                        var obj = {
                            'Paragraph Count': i,
                            'Paragraph Length': paraLength,
                            'Character Count': charCount,
                            'Character Count Minimum': utils.characterCount(articleAdCount)
                        };
                        utils.log(obj);
                    }

                    if (charCount >= utils.characterCount(articleAdCount)) {
                        TnAds.insertAd('after', node, observeAds);
                        articleAdCount += 1;

                        if (debug) utils.log('Ad was inserted after paragraph ' + i + '. Eligible character count was ' + charCount);
                        if (charCount > 0) charCount = 0;
                    }
                }
            }
        },

        refreshTimer: function() {
            TnAds.interactionRefresh();
            bRefreshAds = setTimeout(TnAds.refreshTimer, 5000);
        },

        interactionRefresh: function() {
            var eligibleAds = [],
                timestampInMs = Date.now(),
                timeRemaining = 0;

            for (var key in tntSlots) {
                if (tntSlots.hasOwnProperty(key)) {
                    if (utils.inViewport(tntSlots[key].id) && tntSlots[key].timestamp != null) {

                        // If mobile refresh or ad slot is dynamically injected GAM in-story ads
                        if (mobileRefresh || (window.innerWidth >= 992 && tntSlots[key].type === 'injected')) {

                            /* Prevent first ad from refreshing until asset carousel has been loaded.
                               This ensures that the first ad was viewed after the photo carousel auto height trigger. */
                            if (tntSlots[key].delayRefresh) {
                                var assetCarousel = document.getElementById('asset-photo-carousel');
                                if (assetCarousel) {
                                    var owlCarousel = assetCarousel.querySelector('.owl-carousel');
                                    var tntSlider = assetCarousel.querySelector('.tnt-slider');

                                    if (
                                        owlCarousel && owlCarousel.classList.contains('owl-loaded')
                                        || tntSlider && tntSlider.classList.contains('tnt-slider-loaded')
                                    ) {
                                        tntSlots[key].delayRefresh = false;
                                        tntSlots[key].timestamp = timestampInMs;
                                    } else {
                                        continue;
                                    }
                                } else {
                                    tntSlots[key].delayRefresh = false;
                                }
                            }
                            if (tntSlots[key].timestamp < (timestampInMs - refresh_interval)) {
                                if (tntSlots[key].viewableImpression === true) {
                                    var slotSize;
                                    var adUnitPath = gptAdSlots[tntSlots[key].id].getAdUnitPath();
                                    var pos = gptAdSlots[tntSlots[key].id].getTargeting('pos');
                                    if (mobileRefresh) {
                                        slotSize = utils.limitRefreshSizes(tntSlots[key].id, [[728,90],[300,250],[320,50],[300,50],[2,1]]);
                                    } else {
                                        slotSize = utils.limitRefreshSizes(tntSlots[key].id, filteredSizes);
                                    }

                                    if (slotSize) {
                                        // Maintain the height of the original lazyloaded ad.
                                        document.getElementById(tntSlots[key].id).style['min-height'] = slotSize[0][1] + 'px';

                                        // Recreate slot in order to limit the size mapping to one size so that we don't have reflow.
                                        TnAds.destroySlot(tntSlots[key].id);
                                        TnAds.defineGAMSlot(adUnitPath, tntSlots[key].id, slotSize, pos);

                                        eligibleAds.push(tntSlots[key]);

                                        if (debug) utils.log('Ad ' + tntSlots[key].id + ' is visible and has been refreshed.');
                                    } else if (debug) {
                                        utils.log('Ad ' + tntSlots[key].id + ' is visible but could not be refreshed. The size of the initally loaded ad could not be determined.');
                                    }
                                    tntSlots[key].timestamp = timestampInMs;
                                } else if (debug) {
                                    utils.log('Ad: ' + tntSlots[key].id + ' cannot be refreshed until the ad is visible.');
                                }
                            } else if (debug) {
                                timeRemaining = Math.round((((tntSlots[key].timestamp + refresh_interval) - timestampInMs) / 1000) * 10) / 10;
                                utils.log('Ad ' + tntSlots[key].id + ' is visible and has a remaining life of ' + timeRemaining + ' seconds.');
                            }
                        } else {
                            if (window.innerWidth >= 992 || (window.innerWidth < 992 && tntSlots[key].mobileRefresh)) {
                                if (tntSlots[key].timestamp < (timestampInMs - refresh_interval)) {
                                    if ((tntSlots[key].vendor === 'gam' && tntSlots[key].viewableImpression == true) || tntSlots[key].vendor === 'blox') {
                                        if (debug) utils.log('Ad: ' + tntSlots[key].id + ' is visible and has been refreshed.');
                                        tntSlots[key].timestamp = timestampInMs;
                                        eligibleAds.push(tntSlots[key]);
                                    } else if (debug) {
                                        utils.log('Ad: ' + tntSlots[key].id + ' cannot be refreshed until the ad is visible.');
                                    }
                                } else if (debug) {
                                    timeRemaining = Math.round((((tntSlots[key].timestamp + refresh_interval) - timestampInMs) / 1000) * 10) / 10;
                                    utils.log('Ad: ' + tntSlots[key].id + ' is visible and has a remaining life of ' + timeRemaining + ' seconds.');
                                }
                            } else {
                                if (debug) utils.log('Ad ' + tntSlots[key].id + ' : Ad refresh is disabled in the mobile viewport.');
                                delete tntSlots[key];
                            }
                        }
                    }
                }
            }
            if (eligibleAds.length > 0) TnAds.fetchAds(eligibleAds);
        },

        lazyloadObserver: new IntersectionObserver(function(els, observer) {
            var eligibleAds = [];
            
            els.forEach(function(el) {
                if (tntSlots[el.target.id].alwaysLoad || el.intersectionRatio > 0) {
                    if (tntSlots[el.target.id].alwaysLoad || tntSlots[el.target.id].lazyload) {
                        if (debug && tntSlots[el.target.id].alwaysLoad) utils.log('Ad ' + el.target.id + ' has been immediately loaded due to header bidding.');
                        if (debug && tntSlots[el.target.id].lazyload) utils.log('Ad ' + el.target.id + ' is visible and has been lazyloaded.');
                        tntSlots[el.target.id].initLoad = true;
                        eligibleAds.push(tntSlots[el.target.id]);
                    }
                    if (tntSlots[el.target.id].refresh) tntSlots[el.target.id].timestamp = Date.now();

                    TnAds.lazyloadObserver.unobserve(el.target);
                }
            });
            
            if (eligibleAds.length > 0) TnAds.fetchAds(eligibleAds);
        }, {rootMargin: observerMargin}),

        observeAds: function() {
            var slots = [];

            // Create slot array based off of tntSlots obj
            for (var key in tntSlots) {
                if (tntSlots.hasOwnProperty(key)) {
                    slots.push(document.getElementById(tntSlots[key].id));
                }
            }

            slots.forEach(function(el) {
                TnAds.lazyloadObserver.observe(el);
            });

            // Event Listeners
            if (features.indexOf('refresh_timer') != -1) {
                TnAds.refreshTimer();
                window.addEventListener('scroll', function() {
                    window.clearTimeout(bScrolling);
                    window.clearTimeout(bRefreshAds);
                    
                    bScrolling = setTimeout(function() {
                        if (debug) utils.log('Reset ad refresh timer.');
                        bRefreshAds = setTimeout(TnAds.refreshTimer, 5000);
                    }, 66);
                }, {
                    passive: true
                });
            } else if (features.indexOf('refresh') != -1) {
                window.addEventListener('scroll', TnAds.throttle(TnAds.interactionRefresh, 500));
                window.addEventListener('focus', TnAds.throttle(TnAds.interactionRefresh, 500));

                var carouselControls = Array.from(document.querySelectorAll('.photo-carousel-control'));
                carouselControls.forEach(function(el) {
                    el.addEventListener('click', TnAds.interactionRefresh);
                });
            }
        },

        progTargeting: function() {
            if (typeof __tnt.ads.dfp.dmp !== 'undefined') {
                if (typeof userAudience === 'object') {
                    for (var i = 0; i < userAudience.length; i++) {
                        userAudience[i] = userAudience[i].replace('keyword:', '');
                    }
                    googletag.cmd.push(function() {
                        googletag.pubads().setTargeting('las', userAudience);
                    });
                }
            }
        },

        throttle: function(fn, delay) {
            var context = this,
                time = Date.now(),
                scrollPosition = 0;

            return function() {
                var currentPosition = window.pageYOffset;

                if (currentPosition > scrollPosition) {
                    if ((time + delay - Date.now()) < 0) {
                        fn.apply(context);
                        time = Date.now();
                    }
                }
                scrollPosition = currentPosition;
            };
        },

        verifySlot: function(ad, vendor) {
            try {
                var adSlot = {
                    alwaysLoad: false,
                    id: ad.id,
                    initLoad: false,
                    lazyload: false,
                    refresh: false,
                    sticky: false,
                    timestamp: null,
                    type: 'inline',
                    vendor: vendor,
                    viewableImpression: false
                };

                if (vendor === 'gam') {
                    if (ad.id) {
                        adSlot.id = ad.id;
                    } else {
                        throw ['No id on the ' + vendor + '-ad div:', ad.outerHTML];
                    }
                    if (ad.dataset.lazy || ad.dataset.refresh || ad.dataset.biddingRefresh || ad.dataset.alwaysLoad) {
                        if (ad.dataset.lazy) adSlot.lazyload = true;
                        if (ad.dataset.refresh) {
                            adSlot.refresh = true;
                            if (ad.dataset.mobileRefresh) adSlot.mobileRefresh = true;
                        }
                        if (ad.dataset.biddingRefresh) adSlot.biddingRefresh = true;
                        if (ad.dataset.alwaysLoad) adSlot.alwaysLoad = true;
                    } else {
                        throw ['Ad has already been loaded and is not refreshable', ad.outerHTML];
                    }
                } else if (vendor === 'blox') {
                    var adSettings;
                    if (ad.dataset.tntAds || ad.dataset.tntAdsTmp) {
                        adSettings = (ad.dataset.tntAds) ? JSON.parse(ad.dataset.tntAds) : JSON.parse(ad.dataset.tntAdsTmp);
                        if (adSettings.refresh) {
                            adSlot.refresh = true;
                        } else {
                            throw ['Ad has already been loaded and is not refreshable', ad.outerHTML];
                        }
                        adSlot.id = (adSettings.type == 'position') ? 'blox-ad-position-' + adSettings.region + adSettings.slot : 'blox-ad-position-' + adSettings.region + '1';
                        adSlot.region = adSettings.region;
                    } else {
                        throw ['No settings defined the blox-ad div:', ad.outerHTML];
                    }
                }
                return adSlot;
            } catch(e) {
                for (var i = 0; i < e.length; i++) {
                    if (debug) utils.log(e[i]);
                }
                return false;
            }
        }

    };

    // Public functions
    __tnt.ads.initAdInjection = TnAds.initAdInjection;
    __tnt.ads.lazyloadObserver = TnAds.lazyloadObserver;

    // Defaults
    var adSizes = [[970,250],[970,90],[728,90],[300,250],[320,50],[300,50],[2,1],'fluid'],
        adSizesLg,
        adSizesMd,
        adSizesSm,
        adSizesXs,
        adUnit = '',
        audienceTargeting = (typeof __tnt.ads.dfp !== 'undefined' && typeof __tnt.ads.dfp.dmp !== 'undefined' && (typeof __tnt.ads.dfp.dmp.hideAudience !== 'undefined' || utils.getMeta('tncms:ads').split(',').indexOf('dmp') > -1)) ? true : false,
        bRefreshAds,
        bScrolling,
        debug = utils.debugMode(),
        encrypted,
        features,
        filteredSizes = [],
        refresh_interval,
        subscription = utils.getMeta('tncms:subs') ? true : false,
        userAudience,
        userStatusDefined = false;

    // Initialize
    if (audienceTargeting || subscription) {
        if (audienceTargeting) window.addEventListener('tnt-dmp-audience-extracted-lotame', TnAds._initHandler, false);
        if (audienceTargeting) window.addEventListener('tnt-dmp-audience-extracted-segment', TnAds._initHandler, false);
        if (subscription) {
            if (__tnt.subscription.access.checkedAccess) {
                TnAds._initHandler({type:'tnt-user-status'});
            } else {
                window.addEventListener('tnt-user-status', TnAds._initHandler, false);
            }
        }
    } else {
        window.addEventListener('DOMContentLoaded', TnAds._init, false);
    }  
})(window, document, __tnt.ads.slots, googletag, gptAdSlots);